import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable()

export class UserService {
  constructor(
    private readonly router: Router,
    private http: HttpClient,
    private readonly apiService: ApiService
  ) {}

  private registerUrl = `${environment.apiEndpoint}/api/ccp-register`;
  private apiUser = `${environment.apiEndpoint}/api/rumbles/user/get`;

  register(model) {
      const registerModel = {
          first_name: model.first_name,
          last_name: model.last_name,
          email: model.email,
          ccp_organisation: model.organisation,
          ccp_name: model.cfc_name,
          password: model.password,
          password_confirmation: model.password_confirmation
      };

      const options = {
        body: registerModel,
        method: 'POST'
      };

      return this.http.post(this.registerUrl, options).toPromise<any>();
  }

  createUserFromInvitationOrganisation(model) {
    return this.http.post(
      `${environment.apiEndpoint}/api/rumbles/organisation/invitation/create-user`,
      model
    ).toPromise<any>();
  }

  public postExpired(data) {
    return this.apiService
      .post(`api/rumbles/password/expired`, data)
      .toPromise<any>();
  }

  getLoggedInUser(): Observable<any> {
    const loggedInUser = localStorage.getItem('user_profile');
    if (loggedInUser === null || loggedInUser === 'undefined') {
      return this.http.get(this.apiUser);
    }

    return of(JSON.parse(loggedInUser));
  }

  public getCurrentUser(): Promise<any> {
    return this.apiService
      .get<any>(`api/rumbles/user/get`)
      // .post<any>(`user/current`)
      .toPromise<any>();
  }

  public createSecondaryUser(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/user/member/add`, data)
      .toPromise<any>();
  }

  public updateSecondaryUser(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/user/member/update`, data)
      .toPromise<any>();
  }

  public deleteSecondaryUser(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/user/member/delete`, data)
      .toPromise<any>();
  }

  public restoreSecondaryUser(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/user/member/restore`, data)
      .toPromise<any>();
  }

  public updateUser(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/user/update`, data)
      .toPromise<any>();
  }

  public updateOrganisation(data): Promise<any> {
    return this.apiService
      .post<any>(`api/rumbles/organisation/update`, data)
      .toPromise<any>();
  }
}
