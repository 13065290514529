import { Component } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from './services/user.service';
import { AlertService } from './alerts/alert.service';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired.component.html'
})

export class PasswordExpiredComponent {
  public currentPassword;
  public password;
  public passwordConfirmation;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private readonly router: Router
  ) { }

  submitForm() {
    const postData = {
      current_password: this.currentPassword,
      password: this.password,
      password_confirmation: this.passwordConfirmation
    };

    console.log(postData);

    this.userService.postExpired(postData)
      .then(response => {
        this.alertService.show('Successfully updated password.', 'success');

        this.router.navigate(['/my-account']);
      })
      .catch(msg => {
        console.log(msg);
        const alertService = this.alertService;

        const errors = [];

        if (typeof msg.error.errors.current_password !== 'undefined') {
          msg.error.errors.current_password.forEach(function (val: any) {
            errors.push(val);
          });
        }

        if (typeof msg.error.errors.password !== 'undefined') {
          msg.error.errors.password.forEach(function (val: any) {
            errors.push(val);
          });
        }

        if (typeof msg.error.errors.password_confirmation !== 'undefined') {
          msg.error.errors.password_confirmation.forEach(function (val: any) {
            errors.push(val);
          });
        }

        if (errors.length > 0) {
          alertService.show(errors.join(' <br> '), 'danger');
        } else {
          alertService.show(msg.error.message, 'danger');
        }
      });
  }
}
