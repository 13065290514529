import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { AlertService } from '../../alerts/alert.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-secondary-user-modal',
  templateUrl: 'restore-secondary-user-modal.component.html',
})
export class RestoreSecondaryUserModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  submitBtnName = 'Restore User';
  data: any = {};
  formModel: any = {};

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.mapDataToForm();
  }

  mapDataToForm() {
    this.formModel.id = this.data.individualObject.id;
    this.formModel.first_name = this.data.individualObject.first_name;
    this.formModel.last_name = this.data.individualObject.last_name;
  }

  submitForm() {
    this.userService.restoreSecondaryUser(this.formModel)
      .then(message => {
        this.alertService.show(message.message, 'success');
        this.bsModalRef.hide();
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        console.log(message);
      })
      .catch((msg) => {
        const alertService = this.alertService;

        if (!Array.isArray(msg.error.errors)) {
          msg.error.errors = Object.values(msg.error.errors);
        }

        msg.error.errors.forEach(function (val: any) {
          console.log(val);
          if (!Array.isArray(msg.error.errors)) {
            alertService.show(msg.error.message, 'danger');
          } else {
            val.forEach(function (innerVal: any) {
              alertService.show(innerVal, 'danger');
            });
          }
        });

        this.bsModalRef.hide();
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });
  }
}
