import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../alerts/alert.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html'
})

export class EmailConfirmationComponent implements OnInit, OnDestroy {
  model: any = {};
  private sub: any;
  emailConfirmationKey: string;
  error: any = '';

  formModel: any = {
    password: null,
    passwordConfirmation: null,
  };

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.emailConfirmationKey = params['key'];
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  submitForm() {
    this.authService.postSetPasswordAndConfirmEmailByKey(
        this.emailConfirmationKey,
        this.formModel.password,
        this.formModel.passwordConfirmation
      )
      .then(response => {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          queryParams: {
            success: 'You have successfully confirmed your account, and set your new password. Please login below.'
          },
        };

        this.router.navigate(['/auth/login'], navigationExtras);
      })
      .catch(msg => {
        console.log(msg);
        const alertService = this.alertService;

        const errors = [];

        if (typeof msg.error.errors !== 'undefined') {
          if (typeof msg.error.errors.password !== 'undefined') {
            msg.error.errors.password.forEach(function (val: any) {
              errors.push(val);
            });
          }

          if (typeof msg.error.errors.password_confirmation !== 'undefined') {
            msg.error.errors.password_confirmation.forEach(function (val: any) {
              errors.push(val);
            });
          }
        }

        if (errors.length > 0) {
          alertService.show(errors.join(' <br> '), 'danger');
        } else {
          alertService.show(msg.error.message, 'danger');
        }
      });
  }
}
