import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// containers
import { AuthContainer } from './containers/auth.container';
import { DefaultContainer } from './containers/default.container';
import { DefaultNoSidebarContainer } from './containers/default-no-sidebar.container';
import { LoggedOutDefaultContainer } from './containers/logged-out-default.container';
import { ReportsContainer } from './containers/reports.container';

// components
import { LoginComponent } from './login.component';
import { RegisterComponent } from './register.component';
import { ResetPasswordComponent } from './reset-password.component';
import { DashboardComponent } from './dashboard.component';
import { NotFoundComponent } from './notfound.component';
import { HomeComponent } from './home.component';
import { HowToUseComponent } from './static/how-to-use.component';
import { ManageIndividualComponent } from './administration/manage-individual.component';
import { ManageIndividualArchivedComponent } from './administration/manage-individual-archived.component';
import { SupportComponent } from './static/support.component';
import { GroupSummaryReportComponent } from './reports/group-summary-report.component';
import { FeaturedChildReportComponent } from './reports/featured-child-report.component';
import { ChildScoresReportComponent } from './reports/child-scores-report.component';
import { AssetsReportComponent } from './reports/assets-report.component';
import { AlignResponsesComponent } from './administration/align-responses.component';
import { GetRumblesComponent } from './administration/get-rumbles.component';
import { ManageQuestBlocksComponent } from './administration/manage-quest-blocks.component';
import { ManageAccountComponent } from './administration/manage-account.component';
import { ManageGroupsComponent } from './administration/manage-groups.component';
import { AlignResponsesQuestBlockComponent } from './administration/align-responses-quest-block.component';
import { TrainingEssentialsComponent } from './static/training-essentials.component';
import { SchoolConsentPreInvitationComponent } from './static/school-consent-pre-invitation.component';
import { SchoolConsentConfirmRegistrationComponent } from './static/school-consent-confirm-registration.component';
import { SchoolNswConsentPreInvitationComponent } from './static/school-nsw-consent-pre-invitation.component';
import { EmailConfirmationComponent } from './auth/email-confirmation.component';
import { SchoolTasCreateUserFromInvitationComponent } from './static/school-tas-create-user-from-invitation.component';
import { SchoolQldCathedCfcConsentPreInvitationComponent } from './static/school-qld-cathed-cfc-consent-pre-invitation.component';
import { SchoolQldCathedNonCfcConsentPreInvitationComponent } from './static/school-qld-cathed-non-cfc-consent-pre-invitation.component';
import { ChallengeComponent } from './two-factor/challenge.component';
import { SetupComponent } from './two-factor/setup.component';
import { LockedComponent } from './two-factor/locked.component';
import { RecoveryCodesComponent } from './two-factor/recovery-codes.component';
import { PasswordExpiredComponent } from './password-expired.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthContainer,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'confirm-email/:key',
        component: EmailConfirmationComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      }
    ]
  },
  {
    path: 'two-factor',
    component: DefaultContainer,
    children: [
      {
        path: 'setup',
        component: SetupComponent
      },
      {
        path: 'locked',
        component: LockedComponent
      },
      {
        path: 'challenge',
        component: ChallengeComponent
      },
      {
        path: 'recovery-codes',
        component: RecoveryCodesComponent
      }
    ]
  },
  {
    path: '',
    component: DefaultContainer,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'how-to-use',
        component: HowToUseComponent
      },
      {
        path: 'training-essentials',
        component: TrainingEssentialsComponent
      },
      {
        path: 'manage-individuals',
        component: ManageIndividualComponent
      },
      {
        path: 'manage-individuals/archived',
        component: ManageIndividualArchivedComponent
      },
      {
        path: 'get-rumbles',
        component: GetRumblesComponent
      },
      {
        path: 'quest-blocks',
        component: ManageQuestBlocksComponent
      },
      {
        path: 'manage-groups',
        component: ManageGroupsComponent
      },
      {
        path: 'my-account',
        component: ManageAccountComponent
      },
      {
        path: 'password/expired',
        component: PasswordExpiredComponent
      },
      {
        path: 'align-responses',
        component: AlignResponsesQuestBlockComponent
      },
      {
        path: 'align-responses/:key',
        component: AlignResponsesComponent
      },
      {
        path: 'support',
        component: SupportComponent
      }
    ]
  },
  {
    path: '',
    component: LoggedOutDefaultContainer,
    children: [
      {
        path: 'consent',
        component: SchoolConsentPreInvitationComponent
      },
      {
        path: 'qldcathedcfc-consent',
        component: SchoolQldCathedCfcConsentPreInvitationComponent
      },
      {
        path: 'qldcathed-consent',
        component: SchoolQldCathedNonCfcConsentPreInvitationComponent
      },
      {
        path: 'nsw-consent',
        component: SchoolNswConsentPreInvitationComponent
      },
      {
        path: 'create-new-user',
        component: SchoolConsentConfirmRegistrationComponent
      },
      {
        path: 'tasdoe',
        component: SchoolTasCreateUserFromInvitationComponent
      }
    ]
  },
  {
    path: '',
    component: ReportsContainer,
    children: [
      {
        path: 'report',
        redirectTo: '/report/overview',
        pathMatch: 'full'
      },
      {
        path: 'report/group-summary-report',
        component: GroupSummaryReportComponent
      },
      {
        path: 'report/parent-report',
        component: FeaturedChildReportComponent
      },
      {
        path: 'report/child-scores',
        component: ChildScoresReportComponent
      },
      {
        path: 'report/assets-report/:asset',
        component: AssetsReportComponent
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
